export default {
  feverEnhancementLevels: [
    {
      mainCategory: 1,
      items: [
        736015, 736016, 736017, 736018, 736019, 736020, 736021, 736022, 736023, 736024, 736025, 736026, 736027, 736028,
        736029, 736030, 736031, 736032,
      ],
    },
    {
      mainCategory: 5,
      items: [
        738201, 738202, 738203, 738204, 738205, 738206, 738207, 738208, 738209, 738210, 738211, 738212, 738213, 738214,
        738215, 738216,
      ],
    },
    {
      mainCategory: 10,
      items: [
        736115, 736116, 736117, 736118, 736119, 736120, 736121, 736122, 736123, 736124, 736125, 736126, 736127, 736128,
        736129, 736130, 736131, 736132, 736133, 736134, 736135, 736136, 736137,
      ],
    },
    {
      mainCategory: 15,
      items: [719897, 719898],
    },
  ],
};
